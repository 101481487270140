<template>
  <v-container fluid>
    <v-row class="pt-5 ma-0" align="center" justify="center">
      <v-col cols="12" md="3" class="pb-0">
        <v-text-field
          label="CPF"
          v-model="cpfFormated"
          outlined
          color="textPrimary"
          readonly
        />
      </v-col>
      <v-col cols="12" md="3" class="pb-0">
        <v-text-field
          label="Nome"
          maxlength="70"
          v-model.trim="beneficiaryContractedPlan.name"
          outlined
          color="textPrimary"
          readonly
        />
      </v-col>
      <v-col cols="12" md="3" class="pb-0">
        <v-text-field
          label="Data de Admissão"
          :value="formatter.formatDate(beneficiaryContractedPlan.admissionDate)"
          outlined
          color="textPrimary"
          readonly
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Formatter from '@/shared/formatters/formatters';

export default {
  name: 'AdditionalInformationSimpleVue',

  data: () => ({ beneficiaryContractedPlan: {}, cpfFormated: null }),

  props: { beneficiaryContractedPlanProps: Object },

  watch: {
    beneficiaryContractedPlanProps: {
      deep: true,
      handler(val) {
        if (val) {
          this.beneficiaryContractedPlan = val;
          this.cpfFormated = this.formatter.formatCpf(this.beneficiaryContractedPlan.cpf);
        }
      },
    },
  },

  created() {
    this.formatter = new Formatter();
  },
};
</script>
